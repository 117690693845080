import { AxiosStatic } from "axios";
import { Class } from "./interfaces";

export const getClasses = async (client: AxiosStatic): Promise<Class[]> => {
  const response = await client.get("/sign-up/classes");
  return response.data;
};

export interface SignUpData {
  classId: string;
  userInfo: {
    name: string;
    surname: string;
    phoneNumber: string;
    drivingSchool: string;
  };
}

export const bookClass = async (
  client: AxiosStatic,
  data: SignUpData
): Promise<void> => {
  await client.post("/sign-up/submit", data);
};
