import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCMaeXJUyE22tYnvnPHiWOpN4iFN5eq_Uc",
  authDomain: "nasta-48ecd.firebaseapp.com",
  projectId: "nasta-48ecd",
  storageBucket: "nasta-48ecd.appspot.com",
  messagingSenderId: "82298048583",
  appId: "1:82298048583:web:c30d1e33b9c8d1e19ce162",
};

const app = initializeApp(firebaseConfig);

export { app };
